const pageTem = {
  'type': 'page',
  'id': 'page:304768895489871873',
  'title': '设备列表',
  'toolbar': [],
  'regions': [
    'body'
  ],
  'body': [
    {
      'type': 'crud',
      'id': 'DeviceList:table',
      'draggable': false,
      'api': {
        'method': 'get',
        'url': '/gather/device/info/page',
        'messages': {},
        'requestAdaptor': '',
        'adaptor': '',
        'dataType': 'json',
        'data': {
          '&': '$$',
          'objectId': '${objectId}'
        }
      },
      'perPage': 10,
      'keepItemSelectionOnPageChange': false,
      'maxKeepItemSelectionLength': 11,
      'labelTpl': '${id}',
      'autoGenerateFilter': {
        'columnsNum': 6,
        'showBtnToolbar': false
      },
      'bulkActions': [
        {
          'label': '删除',
          'type': 'button',
          'id': 'u:6c5b53f44832',
          'level': 'primary',
          'onEvent': {
            'click': {
              'weight': 0,
              'actions': [
                {
                  'ignoreError': false,
                  'outputVar': 'responseResult',
                  'actionType': 'ajax',
                  'options': {},
                  'api': {
                    'url': '/gather/device/info/delete',
                    'method': 'post',
                    'requestAdaptor': "api.data=api.data.id.split(',');\r\nreturn api;",
                    'adaptor': '',
                    'messages': {},
                    'dataType': 'json',
                    'data': {
                      'id': '${ids|raw}'
                    }
                  }
                },
                {
                  'componentId': 'DeviceList:table',
                  'ignoreError': false,
                  'actionType': 'reload',
                  'args': {
                    'resetPage': true
                  }
                }
              ]
            }
          },
          'confirmText': '是否批量删除？',
          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:delete')}"
        }
      ],
      'quickSaveApi': '',
      'quickSaveItemApi': '',
      'filterTogglable': true,
      'headerToolbar': [
        {
          'type': 'export-excel',
          'id': 'u:73cb71556d6c',
          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:export')}"
        },
        {
          'type': 'button',
          'label': '新增',
          'icon': 'fa fa-plus pull-left',
          'id': 'DeviceList:add',
          'onEvent': {
            'click': {
              'actions': [
                {
                  'ignoreError': false,
                  'actionType': 'drawer',
                  'drawer': {
                    'closeOnEsc': true,
                    'size': 'md',
                    'type': 'drawer',
                    'closeOnOutside': true,
                    'title': '新增',
                    'body': [
                      {
                        'type': 'form',
                        'title': '表单',
                        'body': [
                          {
                            'name': 'deviceName',
                            'label': '设备名称',
                            'id': 'CREATE:设备名称',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'deviceNo',
                            'label': '设备编号',
                            'id': 'CREATE:设备编号',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'deviceType',
                            'label': '设备类型',
                            'id': 'u:9356d704ffb9',
                            'type': 'select',
                            'options': [
                              {
                                'label': '直连设备',
                                'value': 1
                              },
                              {
                                'label': '网关设备',
                                'value': 2
                              }
                            ],
                            'value': '',
                            'multiple': false,
                            'required': true
                          },
                          {
                            'name': 'sampleId',
                            'label': '所属模板',
                            'id': 'u:eb45d12f6cc1',
                            'type': 'select',
                            'required': true,
                            'source': {
                              'url': '/gather/sample/info/getSampleDataSource?sampleType=${deviceType}',
                              'method': 'get',
                              'requestAdaptor': '',
                              'adaptor': '',
                              'messages': {},
                              'dataType': 'json'
                            },
                            'multiple': false
                          },
                          {
                            'name': 'projectId',
                            'label': '所属项目',
                            'id': 'CREATE:所属项目',
                            'type': 'tree-select',
                            'multiple': false,
                            'onEvent': {},
                            'enableNodePath': false,
                            'showIcon': true,
                            'initiallyOpen': true,
                            'source': {
                              'url': '/system/project/info/projectAllTree',
                              'method': 'get'
                            },
                            'labelField': 'projectName',
                            'valueField': 'id'
                          },
                          {
                            'name': 'iccid',
                            'label': '4G卡标识',
                            'id': 'CREATE:4G卡标识',
                            'type': 'input-text'
                          },
                          {
                            'name': 'imei',
                            'label': 'Imei',
                            'id': 'CREATE:Imei',
                            'type': 'input-text'
                          },
                          {
                            'name': 'icon',
                            'label': '设备图标',
                            'id': 'u:c723b2483926',
                            'type': 'input-image',
                            'autoUpload': true,
                            'proxy': true,
                            'uploadType': 'fileReceptor',
                            'imageClassName': 'r w-full'
                          },
                          {
                            'name': 'status',
                            'label': '状态',
                            'id': 'CREATE:状态(1-启用,2-禁用)',
                            'type': 'input-text',
                            'hidden': true,
                            'value': '1'
                          },
                          {
                            'name': 'remark',
                            'label': '备注',
                            'id': 'CREATE:备注',
                            'type': 'input-text'
                          }
                        ],
                        'api': {
                          'url': '/gather/device/info/add',
                          'method': 'post',
                          'requestAdaptor': '',
                          'adaptor': 'response.data={};\r\nreturn response;',
                          'messages': {
                            'success': '创建成功',
                            'failed': '创建失败'
                          },
                          'dataType': 'json'
                        },
                        'id': 'u:f141fe10696a',
                        'onEvent': {
                          'submitSucc': {
                            'weight': 0,
                            'actions': [
                              {
                                'componentId': 'DeviceList:table',
                                'ignoreError': false,
                                'actionType': 'reload',
                                'args': {
                                  'resetPage': true
                                }
                              }
                            ]
                          }
                        },
                        'name': '设备列表'
                      }
                    ],
                    'showCloseButton': true,
                    'showErrorMsg': true,
                    'showLoading': true,
                    'className': 'app-popover',
                    'id': 'u:9decfaedac64',
                    'resizable': false,
                    'themeCss': {
                      'drawerClassName': {
                        'box-shadow': ' 0px 0px 0px 0px transparent'
                      }
                    }
                  }
                }
              ]
            }
          },
          'primary': true,
          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:add')}"
        },
        {
          'type': 'button',
          'label': '批量新增',
          'icon': 'fa fa-plus pull-left',
          'id': 'DeviceList:init',
          'onEvent': {
            'click': {
              'actions': [
                {
                  'ignoreError': false,
                  'actionType': 'drawer',
                  'drawer': {
                    'closeOnEsc': true,
                    'size': 'md',
                    'type': 'drawer',
                    'closeOnOutside': true,
                    'title': '批量新增',
                    'body': [
                      {
                        'type': 'form',
                        'title': '表单',
                        'body': [
                          {
                            'name': 'deviceNamePrefix',
                            'label': '设备名称前缀',
                            'id': 'CREATE:设备编号',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'deviceNoPrefix',
                            'label': '设备编号前缀',
                            'id': 'CREATE:设备名称',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'devicePrefixNumber',
                            'label': '设备起始值',
                            'id': 'CREATE:设备编号',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'devicePrefixSeek',
                            'label': '设备递增因子',
                            'id': 'CREATE:设备编号',
                            'type': 'input-text',
                            'required': true,
                            'value': '1',
                            'hidden': true,
                            'static': true
                          },
                          {
                            'name': 'deviceCount',
                            'label': '设备数量',
                            'id': 'CREATE:设备编号',
                            'type': 'input-text',
                            'required': true
                          },
                          {
                            'name': 'deviceType',
                            'label': '设备类型',
                            'id': 'u:d27b0ff97c6b',
                            'type': 'select',
                            'required': true,
                            'multiple': false,
                            'options': [
                              {
                                'label': '直连设备',
                                'value': 1
                              },
                              {
                                'label': '网关设备',
                                'value': 2
                              }
                            ],
                            'value': ''
                          },
                          {
                            'name': 'sampleId',
                            'label': '所属模板',
                            'id': 'u:ae12bd75350d',
                            'type': 'select',
                            'multiple': false,
                            'source': {
                              'url': '/gather/sample/info/getSampleDataSource?sampleType=${deviceType}',
                              'method': 'get',
                              'requestAdaptor': '',
                              'adaptor': '',
                              'messages': {},
                              'dataType': 'json'
                            },
                            'required': true
                          },
                          {
                            'name': 'projectId',
                            'label': '所属项目',
                            'id': 'CREATE:所属项目',
                            'type': 'tree-select',
                            'multiple': false,
                            'onEvent': {},
                            'enableNodePath': false,
                            'showIcon': true,
                            'initiallyOpen': true,
                            'source': {
                              'url': '/system/project/info/projectAllTree',
                              'method': 'get'
                            },
                            'labelField': 'projectName',
                            'valueField': 'id'
                          },
                          {
                            'name': 'status',
                            'label': '状态',
                            'id': 'CREATE:状态(1-启用,2-禁用)',
                            'type': 'input-text',
                            'hidden': true,
                            'value': '1'
                          },
                          {
                            'name': 'remark',
                            'label': '备注',
                            'id': 'CREATE:备注',
                            'type': 'input-text'
                          }
                        ],
                        'api': {
                          'url': '/gather/device/info/init',
                          'method': 'post',
                          'requestAdaptor': '',
                          'adaptor': 'response.data={};\r\nreturn response;',
                          'messages': {
                            'success': '创建成功',
                            'failed': '创建失败'
                          },
                          'dataType': 'json'
                        },
                        'id': 'u:7b02a9683922',
                        'onEvent': {
                          'submitSucc': {
                            'weight': 0,
                            'actions': [
                              {
                                'componentId': 'DeviceList:table',
                                'ignoreError': false,
                                'actionType': 'reload',
                                'args': {
                                  'resetPage': true
                                }
                              }
                            ]
                          }
                        },
                        'name': '设备列表'
                      }
                    ],
                    'showCloseButton': true,
                    'showErrorMsg': true,
                    'showLoading': true,
                    'className': 'app-popover',
                    'id': 'u:de3a3463c159',
                    'resizable': false,
                    'themeCss': {
                      'drawerClassName': {
                        'box-shadow': ' 0px 0px 0px 0px transparent'
                      }
                    }
                  }
                }
              ]
            }
          },
          'primary': true,
          'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:init')}"
        },
        {
          'type': 'bulk-actions'
        },
        {
          'type': 'columns-toggler',
          'align': 'right'
        },
        {
          'type': 'drag-toggler',
          'align': 'right'
        }
      ],
      'footerToolbar': [
        {
          'type': 'statistics'
        },
        {
          'type': 'pagination',
          'layout': 'perPage,pager,go'
        }
      ],
      'columns': [
        {
          'name': 'ic',
          'label': '',
          'id': 'LIST:设备图标',
          'type': 'text',
          'placeholder': ''
        },
        {
          'name': 'deviceName',
          'label': '设备名称',
          'id': 'LIST:设备名称',
          'type': 'text',
          'searchable': {
            'clearable': 'true',
            'id': 'u:3a1c3bc68a52',
            'name': '表格模板',
            'type': 'input-text'
          },
          'placeholder': '-',
          'fixed': 'left',
          'toggled': true
        },
        {
          'name': 'deviceNo',
          'label': '设备编号',
          'id': 'LIST:设备编号',
          'type': 'text',
          'searchable': {
            'clearable': 'true',
            'id': 'u:6905229bd3a8',
            'name': '表格模板',
            'type': 'input-text'
          },
          'placeholder': '-',
          'className': 'word-break ',
          'width': '',
          'fixed': 'left'
        },
        {
          'name': 'projectId',
          'label': '所属项目',
          'id': 'LIST:所属项目',
          'type': 'mapping',
          'searchable': {
            'id': 'u:40bd28cde89c',
            'name': 'projectId',
            'type': 'tree-select',
            'label': '所属项目',
            'multiple': false,
            'onEvent': {},
            'enableNodePath': false,
            'showIcon': true,
            'initiallyOpen': true,
            'source': {
              'url': '/system/project/info/projectAllTree',
              'method': 'get'
            },
            'labelField': 'projectName',
            'valueField': 'id'
          },
          'source': {
            'url': '/system/project/info/dataSource',
            'method': 'get'
          }
        },
        {
          'type': 'mapping',
          'map': {
            '0': '离线',
            '1': '在线',
            '-1': '未激活'
          },
          'itemSchema': [
            {
              'type': 'tag',
              'label': '${item}',
              'id': 'u:f7f1287c5655',
              'displayMode': 'normal',
              'hiddenOn': "${AND(${item}!='离线')}",
              'style': {
                'color': '#1C1F27',
                'fontSize': '1.6vh'
              },
              'color': '#F5F5F6',
              'className': 'r-2x'
            },
            {
              'type': 'tag',
              'label': '${item}',
              'id': 'u:c9c8fd16ef60',
              'displayMode': 'normal',
              'hiddenOn': "${AND(${item}!='在线')}",
              'style': {
                'color': '#06891D',
                'fontSize': '1.6vh'
              },
              'color': 'rgba(6,137,29,0.09)',
              'className': 'r-2x'
            },
            {
              'type': 'tag',
              'label': '${item}',
              'id': 'u:3c6cf294063d',
              'displayMode': 'normal',
              'hiddenOn': "${AND(${item}!='未激活')}",
              'style': {
                'color': '#FF2C2C',
                'fontSize': '1.6vh'
              },
              'color': 'rgba(255,44,44,0.06)',
              'className': 'r-2x'
            }
          ],
          'name': "${IF(${online}==1,'1',${online})}",
          'label': '在线状态',
          'value': '-1',
          'id': 'u:8495151d5c8f',
          'placeholder': '--'
        },
        {
          'type': 'switch',
          'label': '设备状态',
          'option': '',
          'name': 'deviceStatus',
          'falseValue': 0,
          'trueValue': 1,
          'value': true,
          'onText': '启用',
          'offText': '禁用',
          'id': 'u:0803340a30bd',
          'searchable': {
            'type': 'select',
            'label': '设备状态',
            'clearable': true,
            'name': 'deviceStatus',
            'options': [
              {
                'label': '启用',
                'value': 1
              },
              {
                'label': '禁用',
                'value': 0
              }
            ],
            'id': 'u:ace4c43c42fe',
            'multiple': false
          },
          'onEvent': {
            'change': {
              'weight': 0,
              'actions': [
                {
                  'ignoreError': false,
                  'outputVar': 'responseResult',
                  'actionType': 'ajax',
                  'options': {},
                  'api': {
                    'url': '/gather/device/info/updateStatus',
                    'method': 'post',
                    'requestAdaptor': '',
                    'adaptor': '',
                    'messages': {},
                    'data': {
                      '&': '$$',
                      'id': '${id}'
                    },
                    'dataType': 'json'
                  }
                }
              ]
            }
          }
        },
        {
          'type': 'mapping',
          'name': 'sampleId',
          'label': '所属模板',
          'source': {
            'url': '/gather/sample/info/getSampleDataSource',
            'method': 'get',
            'requestAdaptor': '',
            'adaptor': '',
            'messages': {}
          },
          'id': 'u:8a8dc7b32b11',
          'placeholder': '-',
          'searchable': {
            'name': 'sampleId',
            'label': '所属模板',
            'id': 'u:eb45d12f6cc1',
            'type': 'select',
            'source': {
              'url': '/gather/sample/info/getSampleDataSource',
              'method': 'get',
              'requestAdaptor': '',
              'adaptor': '',
              'messages': {},
              'dataType': 'json'
            },
            'multiple': false
          }
        },
        {
          'type': 'mapping',
          'value': 1,
          'map': {
            '0': '网关子设备',
            '1': '直连设备',
            '2': '网关设备'
          },
          'itemSchema': {
            'type': 'tag',
            'label': '${item}',
            'id': 'u:f7f1287c5655',
            'displayMode': 'normal',
            'style': {
              'fontSize': '1.6vh'
            },
            'color': "${IF(${item}=='网关子设备','#06891D',IF(${item}=='直连设备','#0052C7','#F21A1B'))}",
            'className': 'r-2x'
          },
          'name': 'deviceType',
          'label': '模板类型',
          'id': 'u:a9245657586f'
        },
        {
          'name': 'remark',
          'label': '备注',
          'id': 'LIST:备注',
          'type': 'text'
        },
        {
          'name': 'alarmCount',
          'label': '报警总数',
          'id': 'LIST:报警总数',
          'type': 'text',
          'placeholder': '0'
        },
        {
          'name': 'dealAlarmCount',
          'label': '已处理报警总数',
          'id': 'LIST:已处理报警总数',
          'type': 'text',
          'placeholder': '0'
        },
        {
          'type': 'datetime',
          'value': 1695890709,
          'name': 'recentlyUpperOnline',
          'label': '最近上线时间',
          'valueFormat': 'x',
          'format': 'YYYY-MM-DD HH:mm:ss',
          'id': 'u:c28417410516'
        },
        {
          'type': 'datetime',
          'value': 1695890709,
          'name': 'recentlyBelowOnline',
          'label': '最近离线时间',
          'valueFormat': 'x',
          'id': 'u:b5de491bc2db'
        },
        {
          'type': 'operation',
          'label': '操作',
          'buttons': [
            {
              'type': 'button',
              'label': '编辑',
              'onEvent': {
                'click': {
                  'actions': [
                    {
                      'ignoreError': false,
                      'actionType': 'drawer',
                      'drawer': {
                        'closeOnEsc': true,
                        'size': 'md',
                        'type': 'drawer',
                        'closeOnOutside': true,
                        'title': '编辑',
                        'body': [
                          {
                            'type': 'form',
                            'title': '表单',
                            'body': [
                              {
                                'name': 'id',
                                'label': '设备id',
                                'id': 'UPDATE:设备id',
                                'type': 'input-text',
                                'hidden': true
                              },
                              {
                                'name': 'deviceName',
                                'label': '设备名称',
                                'id': 'UPDATE:设备名称',
                                'type': 'input-text',
                                'required': true
                              },
                              {
                                'name': 'deviceNo',
                                'label': '设备编号',
                                'id': 'UPDATE:设备编号',
                                'type': 'input-text',
                                'required': true
                              },
                              {
                                'name': 'deviceType',
                                'label': '设备类型',
                                'id': 'u:4d59514d2963',
                                'type': 'select',
                                'options': [
                                  {
                                    'label': '直连设备',
                                    'value': 1
                                  },
                                  {
                                    'label': '网关设备',
                                    'value': 2
                                  }
                                ],
                                'value': '',
                                'multiple': false,
                                'required': true
                              },
                              {
                                'name': 'sampleId',
                                'label': '所属模板',
                                'id': 'u:74f231ce5270',
                                'type': 'select',
                                'required': true,
                                'source': {
                                  'url': '/gather/sample/info/getSampleDataSource?sampleType=${deviceType}',
                                  'method': 'get',
                                  'requestAdaptor': '',
                                  'adaptor': '',
                                  'messages': {},
                                  'dataType': 'json'
                                },
                                'multiple': false
                              },
                              {
                                'name': 'projectId',
                                'label': '所属项目',
                                'id': 'UPDATE:所属项目',
                                'type': 'tree-select',
                                'multiple': false,
                                'onEvent': {},
                                'enableNodePath': false,
                                'showIcon': true,
                                'initiallyOpen': true,
                                'source': {
                                  'url': '/system/project/info/projectAllTree',
                                  'method': 'get'
                                },
                                'labelField': 'projectName',
                                'valueField': 'id'
                              },
                              {
                                'name': 'iccid',
                                'label': '4G卡标识',
                                'id': 'UPDATE:4G卡标识',
                                'type': 'input-text'
                              },
                              {
                                'name': 'imei',
                                'label': 'Imei',
                                'id': 'UPDATE:Imei',
                                'type': 'input-text'
                              },
                              {
                                'name': 'icon',
                                'label': '设备图标',
                                'id': 'u:496fb129f578',
                                'type': 'input-image',
                                'autoUpload': true,
                                'proxy': true,
                                'uploadType': 'fileReceptor',
                                'imageClassName': 'r w-full'
                              },
                              {
                                'name': 'status',
                                'label': '状态',
                                'id': 'UPDATE:状态(1-启用,2-禁用)',
                                'type': 'input-text',
                                'hidden': true,
                                'value': '1'
                              },
                              {
                                'name': 'remark',
                                'label': '备注',
                                'id': 'UPDATE:备注',
                                'type': 'input-text'
                              }
                            ],
                            'api': {
                              'url': '/gather/device/info/edit',
                              'method': 'post',
                              'requestAdaptor': '',
                              'adaptor': 'response.data={};\r\nreturn response;',
                              'messages': {
                                'success': '更新成功',
                                'failed': '更新失败'
                              },
                              'dataType': 'json',
                              'data': {
                                '&': '$$'
                              }
                            },
                            'id': 'u:66419b04de42',
                            'onEvent': {
                              'submitSucc': {
                                'weight': 0,
                                'actions': [
                                  {
                                    'componentId': 'DeviceList:table',
                                    'ignoreError': false,
                                    'actionType': 'reload',
                                    'args': {
                                      'resetPage': true
                                    }
                                  }
                                ]
                              }
                            },
                            'name': '设备列表'
                          }
                        ],
                        'showCloseButton': true,
                        'showErrorMsg': true,
                        'showLoading': true,
                        'className': 'app-popover',
                        'id': 'u:0e0dd7816fef',
                        'resizable': false,
                        'themeCss': {
                          'drawerClassName': {
                            'box-shadow': ' 0px 0px 0px 0px transparent'
                          }
                        }
                      }
                    }
                  ]
                }
              },
              'id': 'u:f5b53cc0f29a',
              'themeCss': {
                'className': {
                  'background:default': 'rgba(255, 44, 44, 0.06)',
                  'font:default': {
                    'color': '#ff2c2c'
                  },
                  'border:default': {
                    'border': '0'
                  },
                  'background:hover': 'rgba(255, 44, 44, 0.06)',
                  'font:hover': {
                    'color': '#ff2c2c'
                  },
                  'border:hover': {
                    'border': '0'
                  },
                  'background:active': 'rgba(255, 44, 44, 0.06)',
                  'font:active': {
                    'color': '#ff2c2c'
                  },
                  'border:active': {
                    'border': '0'
                  }
                }
              },
              'className': 'className-f5b53cc0f29a',
              'icon': 'far fa-edit',
              'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:edit')}"
            },
            {
              'type': 'button',
              'label': '删除',
              'onEvent': {
                'click': {
                  'actions': [
                    {
                      'ignoreError': false,
                      'outputVar': 'responseResult',
                      'actionType': 'ajax',
                      'options': {
                        'silent': false
                      },
                      'api': {
                        'url': '/gather/device/info/delete?id=${id}&objectId=${objectId}',
                        'method': 'post',
                        'requestAdaptor': 'var id = api.data.id;\r\napi.data=[];\r\napi.data[0]=id;\r\nreturn api;',
                        'adaptor': '',
                        'messages': {},
                        'dataType': 'json',
                        'replaceData': false,
                        'data': {
                          '&': '$$'
                        }
                      }
                    },
                    {
                      'componentId': 'DeviceList:table',
                      'ignoreError': false,
                      'actionType': 'reload',
                      'args': {
                        'resetPage': true
                      }
                    }
                  ]
                }
              },
              'confirmText': '数据删除后无法恢复，请确认!',
              'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:delete')}",
              'id': 'u:0f8420382125',
              'themeCss': {
                'className': {
                  'background:default': 'rgba(238, 238, 238, 0.64)',
                  'font:default': {
                    'color': '#707070'
                  },
                  'border:default': {
                    'border': '0'
                  },
                  'background:hover': 'rgba(238, 238, 238, 0.64)',
                  'font:hover': {
                    'color': '#707070'
                  },
                  'border:hover': {
                    'border': '0'
                  },
                  'background:active': 'rgba(238, 238, 238, 0.64)',
                  'font:active': {
                    'color': '#707070'
                  },
                  'border:active': {
                    'border': '0'
                  }
                }
              },
              'className': 'className-0f8420382125',
              'icon': 'fas fa-trash-alt'
            }
          ],
          'id': 'u:3ba1b5bedd76',
          'name': '设备列表',
          'placeholder': '-',
          'fixed': 'right'
        }
      ],
      'perPageAvailable': [
        10,
        20
      ],
      'messages': {},
      'filterSettingSource': [
        'objectCode',
        'objectName',
        'tableName',
        'id',
        'createTime'
      ],
      'perPageField': 'pageSize',
      'pageField': 'pageNum',
      'features': [
        'filter'
      ],
      'itemActions': [],
      'onEvent': {},
      'footable': false,
      'alwaysShowPagination': true,
      'name': '设备列表',
      'autoFillHeight': true,
      'filterColumnCount': 3,
      'bodyClassName': 'zzcy-table'
    }
  ],
  'pullRefresh': {
    'disabled': true
  },
  'style': {},
  'data': {
    'permissions': [
      '*:*:*'
    ],
    'token': '6NizSIkcMKoZ73F2RLK0Gnz9pzf8XFRocx6pGfNqu7rdkTxlw8lQ93ScU0nFhBGQEPWZDYJ3iCAzitjBwIr9DW1cI8ekovhfWavFKMRtmOV7akM01xOYFVrVb30itlP1',
    'locationUrl': 'http://localhost:8001',
    'baseURL': 'http://192.168.1.52:7070/admin-api'
  },
  'asideResizor': false,
  'themeCss': {
    'baseControlClassName': {
      'boxShadow:default': ' 0px 0px 0px 0px transparent',
      'radius:default': {
        'top-left-border-radius': '16px',
        'top-right-border-radius': '16px',
        'bottom-left-border-radius': '16px',
        'bottom-right-border-radius': '16px'
      }
    }
  }
}

export {
  pageTem
}
